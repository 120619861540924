import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {


  private appURL;
  private appDATE;
  private appCLIENT;
  private appMODE;
  private appVERSION : string;

  constructor() { }

  setAppURL(value: string) {
    this.appURL = value;
  }


  setAppCLIENT(value: string) {
    this.appCLIENT = value;
  }
  setAppMODE(value: string) {
    this.appMODE = value;
  }

  setAppVERSION(value: string) {
    this.appVERSION = value;
  }

  setAppDATE(value: string) {
    this.appDATE = value;
  }



  getAppURL() {
    return this.appURL;
  }

  getAppCLIENT() {
    return this.appCLIENT;
  }

  getAppMODE() {
    return this.appMODE;
  }

  getAppVERSION() {
    return this.appVERSION;
  }

  getAppDATE() {
    return this.appDATE;
  }

}
